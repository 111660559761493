// vue
import { computed, ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// utilities
import { FetchOptions } from 'ohmyfetch'

// types
import { Nullable, Student } from '@revolutionprep/types'

// plugins
import createInstance from '@/plugins/repository/lib/createInstance'
import createRepository from '@/plugins/repository/lib/createRepository'

// composables
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useStudentStore = defineStore('student', () => {
  // setup repository
  const instance = createInstance()
  const repository = createRepository(instance)
  const $students = repository('/api/v2/students')

  // composables
  const storage = useStorage()

  // state
  const student = ref<Nullable<Student>>(null)

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'student',
      valueToWatch: student
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'student')

  // getters
  const examsApiToken = computed(() => {
    return student.value?.examsApiToken || ''
  })
  const fullName = computed(() => {
    return student.value?.fullName || ''
  })

  // actions
  async function show (
    id: number,
    config?: FetchOptions
  ): Promise<Student> {
    const { student: _student }: { student: Student } =
      await $students.show(
        id,
        config
      )
    student.value = _student
    return _student
  }

  return {
    examsApiToken,
    fullName,
    show,
    student
  }
})
