// vue
import { ref, watch } from 'vue'

// userflow
import userflow from 'userflow.js'

// pinia + stores
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { useSpoofingStore } from '@/store/spoofing'
import { useStudentStore } from '@/store/student'

// composables
import { useErrorHandler } from '@/composables/errorHandler'

export default {
  install: () => {
    // composables
    const { doHandleError } = useErrorHandler()

    // auth store
    const authStore = useAuthStore()
    const { isLoggedIn } = storeToRefs(authStore)

    // spoofing store
    const spoofingStore = useSpoofingStore()
    const { spoofing } = storeToRefs(spoofingStore)

    // student store
    const studentStore = useStudentStore()
    const { student } = storeToRefs(studentStore)

    // local state
    const isUserflowInitialized = ref(false)

    // initialize userflow
    if (process.env.VUE_APP_USERFLOW_TOKEN && isLoggedIn.value) {
      userflow.init(process.env.VUE_APP_USERFLOW_TOKEN)
      isUserflowInitialized.value = true
    }

    // identify student
    watch(student, async () => {
      if (
        isUserflowInitialized.value &&
        student.value &&
        !userflow.isIdentified() &&
        !spoofing.value
      ) {
        try {
          await userflow.identify(
            student.value?.userId?.toString(),
            {
              actor_id: student.value?.actorId,
              actor_type: student.value?.actorType,
              name: student.value.firstName,
              email: student.value.email,
              grade: student.value?.grade,
              graduation_year: student.value.graduationYear,
              sign_in_count: student.value?.signInCount,
              signed_up_at: student.value?.createdAt
            },
            {
              signature: student.value?.userflowToken
            }
          )
        } catch (error) {
          doHandleError(error as Error, true)
        }
      }
    })
  }
}
