// types
import { Nullable } from '@revolutionprep/types'

// utilities
import { format, setSeconds, startOfToday } from 'date-fns'

/**
 * Formats a number of seconds into H:mm:ss or m:ss
 *
 * @param {Nullable<number>} timeInSeconds
 */

export default function (timeInSeconds: Nullable<number>): string {
  const remainingHours = timeInSeconds
    ? Math.floor(timeInSeconds / 3600)
    : null
  const formattedTimeHours = timeInSeconds
      ? format(setSeconds(startOfToday(), timeInSeconds), 'H:mm:ss')
      : '0:00:00'
  const formattedTimeMinutes = timeInSeconds
      ? format(setSeconds(startOfToday(), timeInSeconds), 'm:ss')
      : '0:00'
  return !timeInSeconds
      ? '0:00'
      : remainingHours
        ? formattedTimeHours
        : formattedTimeMinutes
}
