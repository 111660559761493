// vue
import { ref } from 'vue'

// sentry
import * as Sentry from '@sentry/vue'

// pinia + stores
import { defineStore, storeToRefs } from 'pinia'
import { useSpoofingStore } from '@/store/spoofing'
import { useStudentStore } from '@/store/student'
import { useUserStore } from '@/store/user'

// composables
import { useErrorHandler } from '@/composables/errorHandler'
import { useResetPiniaStore } from '@/composables/resetPiniaStore'
import { useStorage, WatcherGenerator } from '@/composables/storage'

// types
import { RouteLocationNormalized } from 'vue-router'
import { User, UserRole } from '@revolutionprep/types'

// utilities
import { $fetch, FetchError} from 'ohmyfetch'
import { hasRoles } from '@revolutionprep/utils'

export const useAuthStore = defineStore('auth', () => {
  // composables
  const { doHandleError } = useErrorHandler()
  const resetPiniaStore = useResetPiniaStore()
  const storage = useStorage()

  // spoofing store
  const spoofingStore = useSpoofingStore()
  const { spoofeeId } = storeToRefs(spoofingStore)

  // student store
  const studentStore = useStudentStore()

  // user store
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  // state
  const allowedUserRoles = ref<UserRole[]>(['admin', 'student', 'tutor'])
  const allowedSpoofingActorTypes = ['Employee', 'Tutor']
  const isLoggedIn = ref(false)

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'isLoggedIn',
      valueToWatch: isLoggedIn
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'auth')

  // actions
  async function doVerify (route?: RouteLocationNormalized) {
    if (!user.value?.id && isLoggedIn.value) {
      await doLogout()
      return
    }

    // check if user is spoofing
    if (
      route &&
      allowedSpoofingActorTypes.includes(user.value?.actorType || '')
    ) {
      spoofingStore.setSpoofing(route)
    }

    // send back to home url if not an admin or student
    if (!hasRoles(allowedUserRoles.value, user.value?.roles)) {
      window.open(user.value?.homeUrl, '_self')
      return
    }

    return await _doFetchUser()
  }

  async function _doFetchUser () {
    if (!user.value?.id) {
      await doLogout()
      return
    }

    try {
      await userStore.show(user.value?.id)
      const config = {
        params: { include: 'userflow_token' }
      }

      // if user is a student
      if (user.value?.actorType === 'Student' && user.value?.actorId) {
        await studentStore.show(user.value?.actorId, config)
      }

      // if user is an employee or tutor spoofing a student
      if (
        allowedSpoofingActorTypes.includes(user.value?.actorType || '') &&
        spoofeeId.value
      ) {
        await studentStore.show(spoofeeId.value, config)
      }

    } catch (error) {
      doHandleError(error as FetchError)
      await doLogout()
      return
    }
  }

  async function doLogin () {
    // fetch authorized user
    try {
      const { _data } = await $fetch.raw('/api/login', {
        baseURL: process.env.VUE_APP_ORBIT_API_BASE_URL,
        credentials: 'include',
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })

      user.value = _data.user

      await doVerify()

      isLoggedIn.value = Boolean(_data.user)

      Sentry.setUser({
        id: String(user.value?.id) || '',
        actorType: user.value?.actorType
      })

      return _data.user as User
    } catch (error) {
       doHandleError(error as FetchError)
       await doLogout()
       return
    }
  }

  async function doLogout () {
    return new Promise((resolve, reject) => {
      try {
        resetPiniaStore.doResetPiniaStore().auth()
        resetPiniaStore.doResetPiniaStore().student()
        resetPiniaStore.doResetPiniaStore().user()
        window.location.href = `${process.env.VUE_APP_STUDENT_URL}/exams` || ''
        resolve('User was logged out successfully')
      } catch (error) {
        reject(new Error('There was a problem logging out'))
      } finally {
        Sentry.setUser(null)
      }
    })
  }

  return {
    doLogout,
    doLogin,
    doVerify,
    isLoggedIn
  }
})
