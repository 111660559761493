// data fetching
import { $fetch } from 'ohmyfetch'

// pinia + store
import { storeToRefs } from 'pinia'
import { useStudentStore } from '@/store/student'

const createExamsInstance = () => {
  // user store
  const studentStore = useStudentStore()
  const { student } = storeToRefs(studentStore)

  return $fetch.create({
    baseURL: process.env.VUE_APP_EXAMS_API_BASE_URL,
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: student.value?.examsApiToken || ''
    }
  })
}

export default createExamsInstance
