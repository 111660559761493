// ionic + vue
import { Ref } from 'vue'

// router
import { useRouter } from 'vue-router'

// types
import { TranscriptDetailPayload } from '@/types/transcript'

// utilities
import { $fetch } from 'ohmyfetch'

// pinia + stores
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '@/store/global'
import { useStudentStore } from '@/store/student'
import { useTranscriptStore } from '@/store/transcript'

// composables
import { useErrorHandler } from '@/composables/errorHandler'
import { useNavigation } from '@/composables/navigation'
import { useTimer } from '@/composables/timer'

export function useNavigateToNextModule (
  isNextButtonDisabled?: Ref<boolean>
) {
  // router
  const router = useRouter()

  // global store
  const globalStore = useGlobalStore()
  const { isLoadingNextModule, showModalNoAnswers } = storeToRefs(globalStore)

  // student store
  const studentStore = useStudentStore()
  const { examsApiToken } = storeToRefs(studentStore)

  // transcript store
  const transcriptStore = useTranscriptStore()
  const {
    activeQuestionIndex,
    activeSequence,
    activeTranscriptSection,
    activeTranscriptSectionOrBreak,
    transcript,
    transcriptId
  } = storeToRefs(transcriptStore)

  // error handler
  const { doHandleError } = useErrorHandler()

  // timer
  const { doInitializeCountdown, doResetTimer, doStartTimer } = useTimer()

  // navigation
  const { doNavigateToExamPlayer } = useNavigation()

  async function doNavigateToNextModule (scoreWithNoAnswers = false) {
    isLoadingNextModule.value = true
    try {
      if (isNextButtonDisabled !== undefined) {
        isNextButtonDisabled.value = true
      }
      const didScoreSection = await doScoreSection(scoreWithNoAnswers)
      if (!didScoreSection && !scoreWithNoAnswers) {
        isLoadingNextModule.value = false
        return
      }

      // if doScoreSection is successful, increment sequence
      if (activeSequence.value) {
        activeSequence.value++
        await doResetTimer()
      }
    } catch (error) {
      // redirect back to the configure page if error scoring section
      await router.push({ name: 'Configure' })
      doHandleError(error as Error, true)
      isLoadingNextModule.value = false
      return
    } finally {
      if (isNextButtonDisabled !== undefined) {
        isNextButtonDisabled.value = false
      }
    }

    // if there is no active transcript section or break, the exam is complete
    if (!activeTranscriptSectionOrBreak.value) {
      await router.push({ name: 'Complete' })
      isLoadingNextModule.value = false
      return
    }

    // redirect user to break if active section is a Break
    if (activeTranscriptSectionOrBreak.value?.kind === 'Break') {
      await router.push({
        name: 'Break',
        params: {
          transcriptId: transcriptId.value
        }
      })
      isLoadingNextModule.value = false
      return
    }

    // by default, reset the activeQuestionIndex and redirect user to
    // exam player to continue onto next section
    activeQuestionIndex.value = 0
    await doNavigateToExamPlayer(transcriptId.value)
    doInitializeCountdown()
    doStartTimer()
    isLoadingNextModule.value = false
    return
  }

  async function doScoreSection (scoreWithNoAnswers = false) {
    const transcriptDetailAndSectionPayload: {
      transcript_details: { [key: string]: TranscriptDetailPayload }
      elapsed_time: number
    } = {
      transcript_details: {},
      elapsed_time: 0
    }

    if (activeTranscriptSection.value) {
      let sectionHasAnswer = false
      // build the properly formatted transcriptDetailAndSectionPayload
      for (const transcriptDetail of activeTranscriptSection.value.transcript_details) {
        if (!sectionHasAnswer) {
          sectionHasAnswer = Boolean(transcriptDetail.student_answer)
        }
        transcriptDetailAndSectionPayload.transcript_details[String(transcriptDetail?.id)] = {
          answer: transcriptDetail.student_answer,
          crossed_out: transcriptDetail.crossed_out,
          review: transcriptDetail.review,
          elapsed_time: transcriptDetail.elapsed_time
        }
      }
      // set the elapsed section time on the payload
      transcriptDetailAndSectionPayload.elapsed_time = activeTranscriptSection.value.elapsed_time || 0

      if (!scoreWithNoAnswers && !sectionHasAnswer) {
        await globalStore.doCloseAllModalsAndPopovers()
        showModalNoAnswers.value = true
        return false
      }

      if (scoreWithNoAnswers || sectionHasAnswer) {
        const data = await $fetch(
          `/api/transcripts/${activeTranscriptSection.value.id}/score_section`,
          {
            baseURL: process.env.VUE_APP_EXAMS_API_BASE_URL,
            headers: {
              authorization: examsApiToken.value
            },
            body: transcriptDetailAndSectionPayload,
            method: 'PATCH'
          }
        )

        // update the transcript store with the latest transcript data from exams-api
        transcript.value = data.transcript

        return true
      }

    } else {
      throw new Error(
        'We\'re sorry, there was an error processing your request. Please try again.'
      )
    }
  }

  return {
    doNavigateToNextModule
  }
}
