// vue
import { computed, ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// types
import { RouteLocationNormalized } from 'vue-router'

// utils
import { doLog } from '@/utils'

// composables
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useSpoofingStore = defineStore('spoofing', () => {
  // composables
  const storage = useStorage()

  // state
  const spoofing = ref('')

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'spoofing',
      valueToWatch: spoofing
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'spoofing')

  // getters
  const spoofeeId = computed(() => {
    return spoofing.value?.split('-')[1]
      ? Number(spoofing.value?.split('-')[1])
      : null
  })

  // actions
  async function clearSpoofing () {
    doLog('spoofing :: clearSpoofing()', 'info', spoofing.value)
    // per @lawler request: exiting spoof should take you back to
    // student dashboard exams page in spoof mode
    let url = `${process.env.VUE_APP_STUDENT_URL}/exams`
    if (spoofing.value) {
      url += `?spoofing=${spoofing.value}`
    }
    await storage.doRemoveStoreFromStorage('spoofing')
    window.open(url, '_self')
  }

  async function setSpoofing (route: RouteLocationNormalized) {
    // check local storage
    const localStorageSpoofing =
      await storage.doGetStorageProperty('spoofing:spoofing')
    if (localStorageSpoofing) {
      spoofing.value = localStorageSpoofing
    }
    // check route query param
    if (route.query?.spoofing?.toString()) {
      spoofing.value = route.query?.spoofing?.toString()
    }
    doLog('spoofing :: setSpoofing()', 'info', spoofing.value)
  }

  return {
    clearSpoofing,
    setSpoofing,
    spoofeeId,
    spoofing
  }
})
