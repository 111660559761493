// utilities
import { doLog } from '@/utils'
import { $fetch } from 'ohmyfetch'

// store
import { useAuthStore } from '@/store/auth'
import { useSpoofingStore } from '@/store/spoofing'

const createInstance = () => {
  // store
  const authStore = useAuthStore()
  const spoofingStore = useSpoofingStore()

  return $fetch.create({
    baseURL: process.env.VUE_APP_ORBIT_API_BASE_URL,
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    async onRequest ({ request, options }) {
      // if spoofing, add X-SPOOFING header to all orbit requests EXCEPT /users
      // if X-SPOOFING header is added to request to /users,
      // response will return a 404 and the route guard will
      // redirect us away when launching an exam
      if (
        authStore?.isLoggedIn &&
        spoofingStore?.spoofing &&
        !(request as string).includes('users')
      ) {
        options.headers = {
          ...options.headers,
          'X-SPOOFING': spoofingStore.spoofing
        }
      }
      doLog('orbit $fetch :: onRequest()', 'info', {
        request,
        isLoggedIn: authStore.isLoggedIn,
        spoofing: spoofingStore.spoofing,
        options
      })
    },
    async onResponseError ({ response }) {
      if (!response.ok && [400, 401, 403].includes(response.status)) {
        await authStore.doLogout()
      }
    }
  })
}

export default createInstance
