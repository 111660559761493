import { App } from 'vue'
import { FetchOptions } from 'ohmyfetch'
import createExamsInstance from './lib/createExamsInstance'
import createInstance from './lib/createInstance'
import createRepository from './lib/createRepository'

export interface RepositoryInstance {
  index(config?: FetchOptions): Promise<any>
  create(payload?: any, config?: FetchOptions): Promise<any>
  show(id?: number | string, config?: FetchOptions): Promise<any>
  update(id: number | string, payload?: any, config?: any): Promise<any>
  delete(id: number | string, config?: FetchOptions): Promise<any>
}

export default {
  install: (app: App) => {
    // create orbit instance
    const orbit = createInstance()
    // create exams instance
    const exams = createExamsInstance()

    // create repositories
    const orbitInstance = createRepository(orbit)
    const examsInstance = createRepository(exams)

    // provide orbit resources
    app.provide('$avatars', orbitInstance('/api/v2/avatars'))
    app.provide('$courses', orbitInstance('/api/v2/courses'))
    app.provide('$employees', orbitInstance('/api/v2/employees'))
    app.provide('$enrollments', orbitInstance('/api/v2/enrollments'))
    app.provide('$leadSources', orbitInstance('/api/v2/lead_sources'))
    app.provide('$limitedEnrollments', orbitInstance('/api/v2/limited_enrollments'))
    app.provide('$login', orbitInstance('/api/login'))
    app.provide('$logout', orbitInstance('/api/logout'))
    app.provide('$parents', orbitInstance('/api/v2/parents'))
    app.provide('$properties', orbitInstance('/api/v2/properties'))
    app.provide('$propertyRecords', orbitInstance('/api/v2/property_records'))
    app.provide('$students', orbitInstance('/api/v2/students'))
    app.provide('$studyAreas', orbitInstance('/api/v2/study_areas'))
    app.provide('$studyAreaSnapshots', orbitInstance('/api/v2/study_area_snapshots'))
    app.provide('$subjects', orbitInstance('/api/v2/subjects'))
    app.provide('$testDates', orbitInstance('/api/v2/test_dates'))
    app.provide('$tutors', orbitInstance('/api/v2/tutors'))
    app.provide('$tutorPackageTranscripts', orbitInstance('/api/v2/tutor_package_transcripts'))
    app.provide('$users', orbitInstance('/api/v2/users'))

    // provide exams resources
    app.provide('$answers', examsInstance('/api/answers'))
    app.provide('$exams', examsInstance('/api/exams'))
    app.provide('$transcripts', examsInstance('/api/transcripts'))
    app.provide('$transcriptsSummary', examsInstance('/api/transcripts/summary'))
  }
}
