// vue & ionic
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

// stores
import { storeToRefs } from 'pinia'
import { useAnnotationStore } from '@/store/annotation'
import { useAuthStore } from '@/store/auth'
import { useGlobalStore } from '@/store/global'
import { useSpoofingStore } from '@/store/spoofing'
import { useStudentStore } from '@/store/student'
import { useTranscriptStore } from '@/store/transcript'
import { useUserStore } from '@/store/user'

// types
import { Transcript, TranscriptContent } from '@/types/transcript'

// composables
import { useErrorHandler } from '@/composables/errorHandler'
import { useStorage } from '@/composables/storage'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/configure',
  },
  {
    path: '/transcripts/:transcriptId/',
    redirect: { name: 'Start' }
  },
  {
    path: '/configure',
    name: 'Configure',
    meta: {
      requiredAuth: true
    },
    component: () => import('@/views/ConfigureView.vue')
  },
  {
    path: '/loading',
    name: 'Loading',
    component: () => import('@/views/LoadingView.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/ErrorView.vue')
  },
  {
    path: '/transcripts/:transcriptId/start',
    name: 'Start',
    meta: {
      requiredAuth: true
    },
    component: () => import('@/views/StartView.vue')
  },
  {
    path: '/transcripts/:transcriptId/play',
    name: 'ExamPlayer',
    meta: {
      requiredAuth: true
    },
    component: () => import('@/views/ExamPlayerView.vue')
  },
  {
    path: '/transcripts/:transcriptId/review',
    name: 'Review',
    meta: {
      requiredAuth: true
    },
    component: () => import('@/views/ReviewView.vue')
  },
  {
    path: '/transcripts/:transcriptId/complete',
    name: 'Complete',
    meta: {
      requiredAuth: true
    },
    component: () => import('@/views/CompleteView.vue')
  },
  {
    path: '/transcripts/:transcriptId/break',
    name: 'Break',
    component: () => import('@/views/BreakView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/ErrorView.vue'),
    meta: {
      error: {
        title: 'Page Not Found',
        status: 404,
        message: '404 Error'
      }
    }
  }
]

// create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// checks active transcript section/break and exam section,
// then determines where to redirect
function doCheckTranscriptContent (): string {
  const transcriptStore = useTranscriptStore()
  const {
    activeExamSection,
    activeTranscriptSectionOrBreak
  } = storeToRefs(transcriptStore)

  // determine what kind of section we are in
  if (activeTranscriptSectionOrBreak.value?.kind === 'Break') {
    return 'Break'
  } else {
    // if there are no further exam sections to be scored,
    // redirect to /complete page
    if (!activeExamSection.value) {
      return 'Complete'
    }
  }
  return ''
}

// global before guard
router.beforeEach(async (to, _from, next) => {
  // composables
  const { doHandleError } = useErrorHandler()
  const storage = useStorage()

  // annotation store
  useAnnotationStore()

  // auth store
  const authStore = useAuthStore()
  const { isLoggedIn } = storeToRefs(authStore)

  // global store
  const globalStore = useGlobalStore()

  // spoofing store
  useSpoofingStore()

  // student store
  const studentStore = useStudentStore()
  const { student } = storeToRefs(studentStore)

  // transcript store
  const transcriptStore = useTranscriptStore()
  const { activeTranscriptSectionOrBreak } = storeToRefs(transcriptStore)

  // user store
  useUserStore()

  globalStore.doCloseAllModalsAndPopovers()

  // check for authenticated user
  await authStore.doVerify(to)
  if (!isLoggedIn.value && to.meta.requiredAuth) {
    return next({ path: '/loading' })
  }

  // check if logged in user matches local storage transcript
  const localStorageTranscript =
    await storage.doGetStorageProperty('transcript:transcript') as Transcript
  const localStorageTranscriptContent =
    await storage.doGetStorageProperty('transcript:transcriptContent') as TranscriptContent

  // set document title to name of exam
  const localStorageExamTitle =
    await storage.doGetStorageProperty('transcript:examTitle')
  if (localStorageExamTitle) {
    document.title = localStorageExamTitle
  }

  if (
    localStorageTranscript &&
    (localStorageTranscript.user?.handle !== student.value?.userId)
  ) {
    // if transcript in local storage belongs to a different student,
    // delete it from local storage
    await storage.doRemoveStoreFromStorage('transcript')
  }

  // only for routes with transcriptId – redirect to configure page
  // if the transcriptId param does not match transcriptId stored in state.
  // if the exam is already complete, don't redirect to the configure page.
  if (to.params.transcriptId && to.name !== 'Complete') {
    try {
      // check if transcript in local storage if so, sync and validate
      if (localStorageTranscript && localStorageTranscriptContent) {
        await storage.doSyncStorage()
      }
      transcriptStore.doValidateTranscript(Number(to.params.transcriptId))
    } catch (error) {
      doHandleError(error as Error, true)
      await storage.doRemoveStoreFromStorage('transcript')
      return next({ name: 'Configure' })
    }
  }

  // prevent student from navigating directly to break from URL bar
  if (to.name === 'Break') {
    if (activeTranscriptSectionOrBreak.value?.kind === 'Section') {
      return next({
        name: 'ExamPlayer',
        params: {
          transcriptId: to.params.transcriptId
        }
      })
    }
  }

  // redirect to exam view or break
  if (to.name === 'ExamPlayer') {
    const nextRoute = doCheckTranscriptContent()
    if (nextRoute) {
      return next({
        name: nextRoute,
        params: {
          transcriptId: to.params.transcriptId
        }
      })
    }
  }

  return next()
})

export default router
