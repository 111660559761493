// sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

// types
import type { App } from 'vue'
import type { Router } from 'vue-router'

// package.JSON
import packageJSON from '../../package.json'

// stores
import { storeToRefs } from 'pinia'
import { useTranscriptStore } from '@/store/transcript'

export function useSentry (vueApp: App<Element>, router: Router) {
  // state
  const isSentryEnabled = process.env.VUE_APP_SENTRY_ENABLE === 'true'
  const isAppStageNotLocal = process.env.VUE_APP_STAGE !== 'local'

  // transcript store
  const transcriptStore = useTranscriptStore()
  const { transcript } = storeToRefs(transcriptStore)

  function doCaptureException (err: unknown) {
    if (isSentryEnabled) {
      Sentry.setTags({
        transcriptId: transcript.value?.id,
        examId: transcript.value?.exam_id
      })
      Sentry.captureException(err)
    } else {
      console.warn('SENTRY DISABLED ~ Captured Error: ', err)
    }
  }

  function doInitSentry () {
    if (
      isSentryEnabled &&
      isAppStageNotLocal
    ) {
      Sentry.init({
        app: vueApp,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_STAGE,
        release: packageJSON.version,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: [
              process.env.VUE_APP_DOMAIN || '',
              process.env.VUE_APP_ORBIT_API_BASE_URL || '',
              process.env.VUE_APP_EXAMS_API_BASE_URL || '',
              /^\//
            ]
          }),
          new Sentry.Replay()
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.5,
        trackComponents: true,
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        timeout: 2000
      })
    }
  }

  return {
    doCaptureException,
    doInitSentry
  }
}
