// ionic + vue
import { toastController } from '@ionic/vue'
import { computed, ref, watch } from 'vue'
import { closeOutline } from 'ionicons/icons'

// route
import { useRoute } from 'vue-router'

// pinia
import { defineStore } from 'pinia'

// types
import { Nullable } from '@revolutionprep/types'

// composables
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useTimerStore = defineStore('timer', () => {
  // composables
  const storage = useStorage()

  // route
  const route = useRoute()

  // state
  const remainingTimeInSeconds = ref<Nullable<number>>(null)
  const timer = ref<Nullable<number> | undefined>(null)
  const timerEndsAt = ref<Nullable<number>>(null)

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'remainingTimeInSeconds',
      valueToWatch: remainingTimeInSeconds
    },
    {
      propertyName: 'timerEndsAt',
      valueToWatch: timerEndsAt
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'timer')

  // five-minute warning
  const hasFiveMinutesRemaining = computed(() => {
    return remainingTimeInSeconds.value
      ? remainingTimeInSeconds.value <= 300
      : false
  })
  watch(hasFiveMinutesRemaining, async () => {
    if (hasFiveMinutesRemaining.value && route.name?.toString() !== 'Break') {
      // calculate toast duration
      const toastDurationInMilliseconds =
        Number(remainingTimeInSeconds.value) < 30 ?
          (Number(remainingTimeInSeconds.value) * 1000) * 0.8 :
          30000

      await _presentToast(toastDurationInMilliseconds)
    }
  })
  async function _presentToast (duration: number) {
    const toast = await toastController.create({
      header: '5 Minutes Left in This Module',
      message: 'If you\'re still working when time runs out, your work will be automatically saved.',
      position: 'top',
      duration, // self-dismiss after passed in duration in milliseconds
      cssClass: 'custom-toast',
      mode: 'md',
      buttons: [
        {
          icon: closeOutline,
          role: 'cancel'
        }
      ]
    })
    await toast.present()
  }

  return {
    hasFiveMinutesRemaining,
    remainingTimeInSeconds,
    timer,
    timerEndsAt
  }
})
