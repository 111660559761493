// vue-router
import { useRouter } from 'vue-router'

// pinia + stores
import { storeToRefs } from 'pinia'
import { useSpoofingStore } from '@/store/spoofing'

export function useNavigation () {
  // router
  const router = useRouter()

  // spoofing store
  const spoofingStore = useSpoofingStore()
  const { spoofing } = storeToRefs(spoofingStore)

  function doNavigateToStudentDashboard () {
    spoofing.value = ''
    window.open(`${process.env.VUE_APP_STUDENT_URL}/exams`, '_self')
  }

  async function doNavigateToExamPlayer (transcriptId: number | undefined) {
    await router.push({
      name: 'ExamPlayer',
      params: {
        transcriptId
      }
    })
  }

  return {
    doNavigateToExamPlayer,
    doNavigateToStudentDashboard
  }
}
