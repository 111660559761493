// ionic + vue
import { computed, ref } from 'vue'

// types
import { Nullable } from '@revolutionprep/types'

// utilities
import doFormatTime from '@/utils/doFormatTime'

// store
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '@/store/global'
import { useTimerStore } from '@/store/timer'
import { useTranscriptStore } from '@/store/transcript'

// composables
import { useStorage } from '@/composables/storage'

export function useTimer () {
  // state
  const localStorageRemainingTime = ref<Nullable<number>>(null)
  const localStorageTimerEndsAt = ref<Nullable<number>>(null)

  // stores
  const globalStore = useGlobalStore()
  const { isTimerPaused } = storeToRefs(globalStore)
  const timerStore = useTimerStore()
  const {
    remainingTimeInSeconds,
    timer,
    timerEndsAt
  } = storeToRefs(timerStore)
  const transcriptStore = useTranscriptStore()
  const { durationInSeconds } = storeToRefs(transcriptStore)

  // storage
  const storage = useStorage()

  // time display
  const formattedTime = computed(() => {
    return doFormatTime(remainingTimeInSeconds.value)
  })

  // methods
  async function doInitializeCountdown () {
    // get remaining time from local storage
    localStorageRemainingTime.value =
      await storage.doGetStorageProperty('timer:remainingTimeInSeconds')

    localStorageTimerEndsAt.value =
      await storage.doGetStorageProperty('timer:timerEndsAt')

    // initialize countdown to the remaining time if it exists,
    // otherwise use section duration
    remainingTimeInSeconds.value = localStorageRemainingTime.value
      ? localStorageRemainingTime.value
      : durationInSeconds.value

    timerEndsAt.value = localStorageTimerEndsAt.value
      ? localStorageTimerEndsAt.value
      : Date.now() + (durationInSeconds.value * 1000)
  }

  async function doResetTimer () {
    doClearTimer()
    await storage.doRemoveStoreFromStorage('timer')
    remainingTimeInSeconds.value = null
    timerEndsAt.value = null
  }

  function doPauseTimer () {
    if (timer.value) {
      doClearTimer()
      isTimerPaused.value = true
    }
  }

  function doClearTimer () {
    if (timer.value) {
      window.clearInterval(timer.value as unknown as ReturnType<typeof setTimeout>)
      timer.value = null
    }
  }

  function doStartTimer () {
    if (isTimerPaused.value && remainingTimeInSeconds.value) {
      // if timer is paused, recalculate new timer ends at value
      // before starting again
      timerEndsAt.value = Date.now() + (remainingTimeInSeconds.value * 1000)
      isTimerPaused.value = false
    }
    if (!timer.value) {
      timer.value = window.setInterval(() => updateRemainingTime(), 333)
    }
  }

  function updateRemainingTime () {
    if (!timerEndsAt.value) {
      return
    }

    const remainingTime = Math.floor((timerEndsAt.value - Date.now()) / 1000)

    if (remainingTime > 0) {
      remainingTimeInSeconds.value = remainingTime
    } else {
      globalStore.doCloseAllModalsAndPopovers()
      remainingTimeInSeconds.value = 0
      doClearTimer()
    }
  }

  return {
    doClearTimer,
    doInitializeCountdown,
    doPauseTimer,
    doResetTimer,
    doStartTimer,
    formattedTime
  }
}
