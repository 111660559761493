// vue
import { ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// utilities
import { FetchOptions } from 'ohmyfetch'

// types
import { Nullable, User } from '@revolutionprep/types'

// plugins
import createInstance from '@/plugins/repository/lib/createInstance'
import createRepository from '@/plugins/repository/lib/createRepository'

// composables
import { useStorage, WatcherGenerator } from '@/composables/storage'

export const useUserStore = defineStore('user', () => {
  // setup repository
  const instance = createInstance()
  const repository = createRepository(instance)
  const $users = repository('/api/v2/users')

  // composables
  const storage = useStorage()

  // state
  const user = ref<Nullable<User>>(null)

  // watchers
  const statePropsToWatch: WatcherGenerator[] = [
    {
      propertyName: 'user',
      valueToWatch: user
    }
  ]
  storage.doGenerateStateWatchers(statePropsToWatch, 'user')

  // actions
  async function show (
    id: number,
    config?: FetchOptions
  ): Promise<User> {
    const { user: _user }: { user: User } = await $users.show(
      id,
      config
    )
    user.value = _user
    return _user
  }

  async function update (
    id: number,
    payload: Record<string, any> | undefined,
    config?: FetchOptions
  ) {
    const { user: _user }: { user: User } = await $users.update(
      id,
      payload,
      config
    )
    user.value = _user
    return _user
  }

  return {
    show,
    update,
    user
  }
})
